import https from '@/router/https'

export function getPage(param) {
    return https.fetchGet('/mallapp/shoppingcart/page', param);
}

export function delShop(param) {
    return https.fetchPost('/mallapp/shoppingcart/del', param);
}

export function editCar( param) { 

    return https.fetchPut('mallapp/shoppingcart',param)
}